import GetComboRequest from '@/models/saleType/SenToSale';
import OptionSelect from '@/models/shared/OptionSelect';
import { AxiosResponse } from 'axios';
import ApiService from '../ApiService';
import GeneralService from '../interfaces/GeneralService';
import MessageService from '../interfaces/MessageService';
import SwalMessageService from '../SwalMessageService';

export default class SaleTypeService implements GeneralService{
    apiController = "/SaleType";
    messageService: MessageService = new SwalMessageService();

    
    async searchSaleTypeOptions(filter: GetComboRequest): Promise<AxiosResponse<OptionSelect[]>> {
        return  ApiService.get<OptionSelect[]>(this.apiController, 
            `combo?`
            + `criteria=${filter.filterName}`
            + (filter.isTypeSaleQuote == undefined ? '' : 
            `&isTypeSaleQuote=${filter.isTypeSaleQuote}`)
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
}