import ClientService from '@/core/services/client/ClientService';
import BillService from '@/core/services/bill/BillService';
import TableSalesToBillService from '@/core/services/bill/interfaces/TableSalesToBillService';
import ClientComboService from '@/core/services/client/interfaces/ClientComboService';
import { SaveValuesToStore } from "@/core/shared/AssingProps";
import FiltersSalesToBill from "@/models/bill/filters/FiltersSalesToBill";
import SalesToBillRow from "@/models/bill/SalesToBillRow";
import PaginationRequest from "@/models/general/PaginationRequest";
import OptionSelect from '@/models/shared/OptionSelect';
import store from "@/store";
import { Actions } from '@/store/commons/ActionsTable';
import { Mutations } from "@/store/commons/MutationsTable";
import Modules from "@/store/enums/Modules";
import { Action, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import TableSalesToBillState from "../../models/TableSalesToBillState";
import BillAmount from '@/models/bill/BillAmount';
enum ActionsOwner {
    SEARCH_CLIENTS = 'SEARCH_CLIENTS'
    , CHANGE_SELECTED_SALE_IDS = 'CHANGE_SELECTED_SALE_IDS'
}

enum MutationsOwner {
    SET_CLIENTS = 'SET_CLIENTS'
    , SET_LOADING_CLIENTS = 'SET_LOADING_CLIENTS'
    , SET_SELECT_IDS = 'SET_SELECT_IDS'
}

@Module({dynamic: true, store, namespaced: true, name: Modules.TableSalesToBill})
export default class TableSalesToBillModule extends VuexModule implements TableSalesToBillState {
    saleIdsSelected = [] as string[];
    selects = { clientOptions: [] as OptionSelect[] };
    records = [] as SalesToBillRow[];
    totalRecords = 0;
    pagination = {
        currentPage: 1
        , pageSize: 10 //default
    } as PaginationRequest;
    filters = {
        clientId: ''
        , endDate: ''
        , startDate: ''
        , porcentage: ''
    } as FiltersSalesToBill;
    loading = false;
    loadingSelectClient = false;

     /**
     * Opciones de los clientes
     */
    get getClientOptions(): OptionSelect[] {
        return this.selects.clientOptions;
    }
    get bills(): BillAmount[] {
        return this.records
            .filter(r => this.saleIdsSelected
                .includes(r.id))
            .map(({id, amount, folio}, index) => ({
                positionNumber: index + 1
                , amount: amount
                , saleId:  id
                , saleFolio: folio
            }));
    }
    get isSelected() {
        return (id: string) => this.saleIdsSelected.includes(id);
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: SalesToBillRow[]){
        this.records = table;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: unknown){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: unknown){
        SaveValuesToStore(this.filters, filters);
    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }
    @Mutation
    [MutationsOwner.SET_CLIENTS](options: OptionSelect[]){
        this.selects.clientOptions = options;
    }
    @Mutation
    [MutationsOwner.SET_LOADING_CLIENTS](value: boolean){
        this.loadingSelectClient = value;
    }
    @Mutation
    [MutationsOwner.SET_SELECT_IDS](newSelected: boolean){
        this.loadingSelectClient = newSelected;
    }
   
    @Mutation
    resetSaleIdsSelected(){
        this.saleIdsSelected = [];
    }
    @Mutation
    selectAllSalesRows(){
        this.saleIdsSelected = this.records.map(sale => sale.id);
    }

    @MutationAction
    async [ActionsOwner.CHANGE_SELECTED_SALE_IDS]({id, isAdded}){
        let saleIdsSelected = [...this.saleIdsSelected];
        if (isAdded && !this.context.getters.isSelected(id)){
            saleIdsSelected.push(id)
        } else if (!isAdded){
            //si se deselecciono se debe quitar del array
            saleIdsSelected = this.saleIdsSelected.filter(function(idAdded) { return idAdded != id; })
        }
        return { saleIdsSelected }
    }

     /**
     * Se obtienen los datos de la tabla de clientes mediante la paginación
     */
    @Action
    async [Actions.SEARCH_TABLE_DATA](){
       this.context.commit(Mutations.SET_VALUE_LOADING, true);
        
        const resp = (await (service.searchTableInfo(this.pagination, this.filters)
            .finally(() => this.context.commit(Mutations.SET_VALUE_LOADING, false)))).data ??
            {
                data: [] as SalesToBillRow[]
               , totalPages: 0
               , currentPage: this.pagination.currentPage
            }

        this.context.commit(Mutations.SET_TABLE_DATA, [ 
            {
                folio: "VNT-1022",
                clientName: "José Bernabe Kumul",
                date: "01-05-2022",
                amount: 5655.10,
                productNames: "Perfiles (2), Aluminios (5)"
                , id: "G12W"
            },
            {
                folio: "VNT-1038",
                clientName: "Halrey Medina",
                date: "30-08-2022",
                amount: 7005.80,
                productNames: "Perfiles (3), Aluminios (5)"
                , id: "QW12"
            },
            {
                folio: "VNT-123",
                clientName: "Jose Jose",
                date: "30-08-2022",
                amount: 1200,
                productNames: "Perfiles (3), Aluminios (5)"
                , id: "QW15-QW15-QW15-QW15-QW15"
            }
        ]);
        this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
        this.context.commit(Mutations.SET_TOTAL_RECORDS, 2);
        this.selectAllSalesRows();
   }

    /**
       * Realiza un busqueda con la nueva paginación
       * @param pagination paginación de la tabla
       */
    @Action
    async [Actions.CHANGE_PAGE](pagination: unknown){
          this.context.commit(Mutations.SET_PAGINATION, pagination);
          this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }

    /**
     * Filtros que se aplican y despues se hace la busqueda de los datos
     * @param filters filtros nuevos
     */
    @Action
    async [Actions.UPDATE_FILTERS](filters: unknown){
        //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
        this.context.commit(Mutations.SET_FILTERS, filters);
        this.context.commit(Mutations.SET_PAGINATION, {
          currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
        });
        this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }

    @Action({ commit: MutationsOwner.SET_CLIENTS })
    async [ActionsOwner.SEARCH_CLIENTS](filterName: string){
        this.context.commit(MutationsOwner.SET_LOADING_CLIENTS, true);
        return (await serviceClient.searchClientOptions(filterName)
            .finally( () => this.context.commit(MutationsOwner.SET_LOADING_CLIENTS, false))).data ?? []
    }

}

/**
 * Servicio del store (no locolocamos adentro por que seria una propiedad del store)
 */
const service =  new BillService() as TableSalesToBillService;
const serviceClient =  new ClientService() as ClientComboService;

