import Actions from './Actions';
import Modules from "@/store/enums/Modules";
import store from "@/store";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations } from './Mutations';
import SaleTypeState from '../../models/SaleTypeState';
import OptionSelect from '@/models/shared/OptionSelect';
import SaleTypeService from '@/core/services/saleType/SaleService';
import GetComboRequest from '@/models/saleType/SenToSale';


@Module({dynamic: true, store, namespaced: true, name: Modules.SaleTypeModule})
export default class SaleTypeModule extends VuexModule implements SaleTypeState {
    loading = false;
    salesType = [] as OptionSelect[];
    
    get getSaleTypeOptions(): OptionSelect[] {
        return this.salesType;
    }

    @Mutation
    [Mutations.SET_SALE_TYPE](value: OptionSelect[]){
        this.salesType = value;
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }

    @Action({ commit: Mutations.SET_SALE_TYPE })
    async [Actions.SEARCH_SALES_TYPE](filter: GetComboRequest){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.searchSaleTypeOptions(filter)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }

}

const service =  new SaleTypeService();